/* eslint-disable vue/prop-name-casing */
import Vue from "vue";
import { mdiLogout, mdiHelpCircleOutline, mdiBrightness4, mdiBrightness7 } from "@mdi/js";

export default Vue.extend({
  name: "AppToolbarComponent",
  props: {
    LeftDrawer: {
      type: Boolean,
      default: () => false,
    },
    RightDrawer: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      Theme: false as boolean,
      IconLogout: mdiLogout,
      IconHelp: mdiHelpCircleOutline,
      IconDark: mdiBrightness7,
      IconLight: mdiBrightness4,
    };
  },
  methods: {
    onThemeChange(): void {
      this.$data.Theme = !this.$data.Theme;
      this.$vuetify.theme.dark = this.$data.Theme;
    },
    onLeftBtnClick(): void {
      this.$emit("LeftDrawerClick");
    },
    onRightBtnClick(): void {
      this.$emit("RightDrawerClick");
    },
    Logout(): void {
      this.$store.dispatch("oidcStore/signOutOidc");
    },
  },
});
